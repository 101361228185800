import React from "react";
import { motion } from "framer-motion";
import { FaGlobeAmericas  } from 'react-icons/fa'
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="not-found-container">
      <motion.div
        className="globe-container"
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 10, ease: "linear" }}
      >
        <FaGlobeAmericas  className="globe"/>
      </motion.div>

      <div className="message-container">
        <h1 className="error-code">404</h1>
        <p className="error-message">Lost in the map? We couldn’t find the page you’re looking for.</p>
        <a href="/" className="home-link">Go Back Home</a>
      </div>
    </div>
  );
};

export default NotFound;