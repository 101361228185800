import React from 'react'
import './Carousel.css'
import Slider from "react-slick"; // Import React Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselSlides from './CarouselSlides'
import { FaFacebookF , FaInstagram  } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
import { BsArrowDown, BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal'

const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} custom-next-arrow`} style={{right: '12%', top: '85%'}} onClick={onClick}>
        <BsChevronRight className="custom-arrow-icon" fill="#A4FF9661" />
      </div>
    );
  };
  
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} custom-prev-arrow`} style={{left: '100%', top: '85%'}} onClick={onClick}>
        <BsChevronLeft className="custom-arrow-icon" fill="#A4FF9661" />
      </div>
    );
  };

const Carousel = () => {
    const settings = {
        dots: true, // Enable dots navigation
        infinite: true, // Infinite loop
        speed: 500, // Slide speed in milliseconds
        slidesToShow: 1, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        autoplay: false, // Enable autoplay
        autoplaySpeed: 2000, // Speed of autoplay in milliseconds
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      };
    
      return (
        <div className='carousel_section'>
            <div className="carousel_slide-social">
                <span>
                    <Link><FaFacebookF size={20} color="#7ee3f5" /></Link>
                </span>
                <span>
                    <Link><FaXTwitter size={20} color="#7ee3f5" /></Link>
                </span>
                <span>
                    <Link><FaInstagram size={20} color="#7ee3f5" /></Link>
                </span>
            </div>
            <div className="carousel_slide-scroll d-md-flex d-none">
                <BsArrowDown size={20} color="#7ee3f5" />
                <span className="scroll-down-text">Scroll Down</span>
            </div>
            <Slider {...settings}>
                {CarouselSlides.map((slides, index) => (
                    <div className='carousel_slide'>
                        <div className="carousel_slide-img" style={{backgroundImage: `url(${slides.img})`}}>
                          <div className="carousel_slide-overlay"></div>
                          <div className="text-content">
                            <div className='text-content-upper'>
                              <div className='d-flex w-50 align-items-start text-content-upper'>
                                <Fade>
                                  <h4 className='text-light text-uppercase fw-light font-grotesque'>{slides.header}</h4>
                                </Fade>
                              </div>
                              <Fade direction='up' className='w-100 d-flex align-items-center justify-content-center'>
                                <h1>{slides.title}</h1>
                              </Fade>
                            </div>
                            <Fade direction='up' className='w-100 d-flex align-items-center justify-content-center'>
                              <div className='text-content-lower'>
                                  <p>{slides.desc}</p>
                              </div>
                            </Fade>
                          </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
      );
    };

export default Carousel