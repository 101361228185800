import React, { useState, useRef } from 'react';
import './Projects.css';
import { Heading } from '../../General/General';
import Slider from "react-slick"; // Import React Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectsSlides from './projectsSlides';
import { Fade } from "react-awesome-reveal";
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';

const UpArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} up_next-arrow`} onClick={onClick}>
        <BsChevronUp  />
      </div>
    );
  };
  
  const DownArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} up_down-arrow`} onClick={onClick}>
        <BsChevronDown />
      </div>
    );
  };

const Projects = () => {
    const [currentSlide, setCurrentSlide] = useState(0); // State to track the active slide
    const sliderRef = useRef(null); // Ref to access Slider instance

    const settings = {
        dots: true, // Disable dots navigation
        infinite: true, // Infinite loop
        speed: 500, // Slide speed in milliseconds
        slidesToShow: 1, // Number of slides to show at once    
        slidesToScroll: 1, // Number of slides to scroll at once
        autoplay: false, // Disable autoplay
        autoplaySpeed: 2000, // Speed of autoplay in milliseconds
        nextArrow: <DownArrow />,
        prevArrow: <UpArrow />,
        vertical: true,
        verticalSwiping: true,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex), // Update active slide on change
    };

    const handleThumbnailClick = (index) => {
        setCurrentSlide(index);
        sliderRef.current.slickGoTo(index); // Go to the clicked slide
    };

    return (
        <div className='projects__Section'>
            <div className='position-relative'>
                <Heading styleUpdate="projectContent" btnText="Latest Projects" />
                <Slider {...settings} ref={sliderRef}>
                    {ProjectsSlides.map((slide, index) => (
                        <div className="items" key={index}>
                            <div
                                className='position-relative'
                                style={{
                                    backgroundImage: `url(${slide.img})`,
                                    height: '500px',
                                    backgroundPosition: 'center center',
                                    backgroundSize: 'cover'
                                }}
                            >
                                <div
                                    className="projects__overlay position-absolute w-100 top-0 left-0 z-2"
                                    style={{
                                        height: '500px',
                                        background: 'rgba(0,0,0,0.5)'
                                    }}
                                ></div>
                                <div
                                    className="project_text-content position-relative pt-5 z-3 text-center d-flex align-items-center justify-content-center"
                                >
                                    <div style={{ width: '60%' }}>
                                        <Fade direction='up'>
                                            <h1 className='text-white display-4 fw-bold'>{slide.title}</h1>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div
                    className='d-flex flex-row align-items-center justify-content-center gap-3 z-2 w-100'
                    style={{bottom: '5%', position: 'absolute'}}>
                    {ProjectsSlides.map((slide, index) => (
                        <div
                            key={index}
                            onClick={() => handleThumbnailClick(index)} 
                            style={{
                                height: 'clamp(100px, 14vw, 150px)',
                                width: 'clamp(150px, 14vw, 220px)',
                                cursor: 'pointer', 
                                border: currentSlide === index ? '2px solid white' : 'none' // Highlight active thumbnail
                            }}
                        >
                            <div
                                style={{
                                    backgroundImage: `url(${slide.img})`,
                                    height: 'clamp(100px, 14vw, 150px)',
                                    backgroundPosition: 'center center',
                                    backgroundSize: 'cover'
                                }}
                            >
                                
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Projects;
