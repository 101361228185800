import Carousel1 from '../../../assets/img/carousel/carousel1.jpg'
import Carousel2 from '../../../assets/img/carousel/carousel2.jpg'
import Carousel3 from '../../../assets/img/carousel/carousel3.jpg'
import Carousel4 from '../../../assets/img/carousel/carousel4.jpg'

const CarouselSlides = [
    { img: Carousel1, header: 'Revolutionizing Data Use', title: 'Transforming Location Intelligence', desc: 'Discover Argus-X: A cutting-edge platform turning geospatial data into actionable insights, empowering decisions in retail, education, and security sectors.' },
    { img: Carousel2, header: 'Tools That Drive Results', title: 'Key Features of Argus-X', desc: 'Explore tools like Map Explorer, real-time analytics, and AI integration. Visualize trends, optimize resources, and uncover hidden patterns to drive innovation.' },
    { img: Carousel3, header: 'Smarter, Faster Decisions', title: 'Real-Time Decision Support', desc: 'With advanced predictive analytics and real-time data processing, Argus-X helps organizations anticipate trends and make informed decisions.' },
    { img: Carousel4, header: 'Versatile Solutions', title: 'Industry Use Cases', desc: 'From optimizing retail supply chains to enhancing campus safety and predicting crime hotspots, Argus-X delivers versatile solutions for diverse challenges.' },
]

export default CarouselSlides;