const NavLinks = [
  {menu: 'Home', link: '/'},
  { 
    menu: 'About Us', 
    link: '/About',
    submenu: [
      { menu: 'Who We Are', link: '/About' },
      { menu: 'Vision & Mission', link: '/About' },
      { menu: 'Core Values', link: '/About' },
      { menu: 'Join Us', link: '/About' },
    ]
  },
  {menu: 'Services', link: '/Services',},
  {menu: 'Portfolio', link: '/Portfolio',},
  {menu: 'News', link: '/News',},
  {menu: 'Contact', link: '/Contact',},
]


export default NavLinks