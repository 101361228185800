import React from 'react'
import './Offers.css'
import { CheckBox } from '../../icons/Icons'
import OfferImg from '../../../assets/img/Offer.jpg'
const Offers = () => {
    const Offers = ["Empower businesses across retail, education, and security to make data-driven decisions using advanced location intelligence solutions.", "Provide real-time geospatial data visualization and predictive analytics to optimize operations and resource allocation.", "Deliver scalable and customizable tools, like the Argus-X Suite, designed to meet diverse needs, from small businesses to large organizations.", "Enhance operational efficiency and security by integrating AI, machine learning, and big data analytics into actionable insights."]

  return (
    <div className='px-5 offers__section'>
        <div className="row">
            <div className="col-md-6">
                <ul>
                    {Offers.map((offer, index) => (
                        <li key={index} className='py-2 text-start d-flex flex-row align-items-center gap-3'><CheckBox size={35} color='#000' />{offer}</li>
                    ))}
                </ul>
            </div>
            <div className="col-md-6">
                <div style={{backgroundImage: `url(${OfferImg})`, backgroundPosition: 'center center', backgroundSize: 'cover', height: '100%', backgroundAttachment: 'fixed', backgroundRepeat: 'no-repeat'}}></div>
            </div>
        </div>
    </div>
  )
}

export default Offers