  import React, { useEffect, useRef, useState } from 'react'
  import './General.css'
  import { FaArrowRight, FaPlus, FaMinus } from 'react-icons/fa'
  import { Shield } from '../icons/Icons';
  import { useInView } from 'react-intersection-observer';
  import Typewriter from 'typewriter-effect';
  import { Fade } from 'react-awesome-reveal';

  export const Button = ({ children, path, txt_color, border_color }) => {
    return (
      <div className='button-style' style={{color: txt_color, borderColor: border_color}} link={path}>
        <FaArrowRight />
        <span className='position-relative' style={{zIndex: '1'}}>{children}</span>
      </div>
    )
  }

  export const Card = ({ icon, title, desc, c_title, c_count, position, endState }) => {
    const [count, setCount] = useState(0); // State to track current count
    const [inView, setInView] = useState(false); // Whether the card is in view or not
    const cardRef = useRef(null); // Ref to the card element
    const intervalRef = useRef(null); // Ref to hold the interval ID
  
    // Detecting if the card is in view using IntersectionObserver
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          console.log("IntersectionObserver callback triggered");
          console.log(`Card in view: ${entry.isIntersecting}`);
          setInView(entry.isIntersecting); // Set inView to true when the card is visible
        },
        { threshold: 0.1 } // Trigger when 10% of the card is in view (adjust as needed)
      );
  
      if (cardRef.current) {
        observer.observe(cardRef.current);
      }
  
      // Cleanup on unmount
      return () => {
        if (cardRef.current) {
          observer.unobserve(cardRef.current);
        }
      };
    }, []);
  
    // Function to update the count when the card is in view
    useEffect(() => {
      console.log(`In view: ${inView}, Count: ${count}, Target Count: ${c_count}`);
      
      if (inView && count < c_count) {
        console.log("Card is in view! Starting counter...");
  
        // Start counting without checking intervalRef to make sure it runs each time
        intervalRef.current = setInterval(() => {
          setCount((prev) => {
            if (prev < c_count) {
              return prev + 1; // Increment by 1 each time
            }
            clearInterval(intervalRef.current); // Stop once we reach the target count
            intervalRef.current = null; // Reset interval reference
            return c_count; // Ensure we set it to the exact target number
          });
        }, 1); // Speed of the counter animation (ms)
      }
  
      // Cleanup the interval when the component unmounts or inView changes
      return () => {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      };
    }, [inView, count, c_count]); // Ensure the effect runs when inView, count, or c_count changes
  
    return (
      <div
        ref={cardRef}
        className='card-comp p-3 d-flex flex-column align-items-center justify-content-center position-relative'
        style={{ top: `${position}` }}
      >
        <div className='position-absolute' style={{ top: '-8rem' }}>
          <img src={icon} style={{ width: '60%' }} alt="" />
        </div>
        <div className='d-flex flex-column align-items-center justify-content-center gap-3' style={{ marginTop: '5rem' }}>
          <Fade cacade direction='up' className='w-100'>
            <div className='d-flex flex-column gap-4 pb-4' style={{ width: '100%' }}>
              <h1>{title}</h1>
              <p>{desc}</p>
            </div>
          </Fade>
          <div className='divider' style={{ width: '95%' }}></div>
          <div className='d-flex flex-row justify-content-around align-items-center' style={{ width: '95%' }}>
            <h5 className='text-start' style={{ width: '60%' }}>{c_title}</h5>
            <span className='count_number'>{count}{endState}</span> {/* This will show the animated count */}
          </div>
        </div>
      </div>
    );
  };

  export const CardFlow = ({ icon, title, desc, position }) => {
    return (
      <div className='cardFlow-comp p-3 d-flex flex-column align-items-center justify-content-center position-relative gap-3' style={{top: `${position}`}}>
        <div className='cardFlow-icon_container'>
          {icon}
        </div>
        <div className='d-flex flex-column align-items-center justify-content-center gap-3' >
          <Fade>
            <div className='d-flex flex-column gap-4 pb-4' style={{width: '85%'}}>
              <h1>{title}</h1>
              <p>{desc}</p>
            </div>
          </Fade>
        </div>
      </div>
    )
  }

  export const Accordion = ({ item, toggleAccordion, activeIndex }) => {
    return (
      <div className="accordion">
        {item.map((it, index) => (
          <div key={index} className="accordion-item">
            <div
              className="accordion-header d-flex justify-content-between align-items-center"
              onClick={() => toggleAccordion(index)}
            >
              <h3 className='accordion__title'>{it.title}</h3>
              <span className="accordion-icon-container">
                <span
                  className={`accordion-icon ${
                    activeIndex === index ? "icon-active" : "icon-hidden"
                  }`}
                >
                  <FaMinus color="#000"/>
                </span>
                <span
                  className={`accordion-icon ${
                    activeIndex !== index ? "icon-active" : "icon-hidden"
                  }`}
                >
                  <FaPlus color="#000"/>
                </span>
              </span>
            </div>
            <div
              className={`accordion-content ${
                activeIndex === index ? "active" : ""
              }`}
            >
              <p className='px-4'>{it.content}</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  export const Heading = ({ styleUpdate = '', btnText, desc, title }) => {
    const { ref, inView } = useInView({
      triggerOnce: true, // Animation triggers only once
      threshold: 0.5,    // Trigger when 50% of the section is visible
    });
  
    return (
      <div ref={ref} className="mb-5 d-flex justify-content-center align-items-center flex-column gap-3 projectContent">
        <Shield size="48" color="#32b1bb" />
        <div
          className={`w-50 d-flex flex-column gap-2 align-items-center justify-column-center ${styleUpdate}`}
        >
          <h1>
            {inView && (
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString(title)
                    .pauseFor(100) // Pause for a moment after typing
                }}
                options={{
                  strings: ['Step Into the Future of Innovation'],
                  autoStart: true,
                  loop: true,
                  // deleteSpeed: 0,
                  // delay: 75,
                  // onComplete: () => {}
                }}
              />
            )}
          </h1>
          <Fade direction='up'>
            <p>
              {desc}
            </p>
          </Fade>
          <Button border_color="#32b1bb" txt_color="#32b1bb">{btnText}</Button>
        </div>
      </div>
    );
  };