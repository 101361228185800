import React from 'react'
import './Services.css'
import { GoShieldLock } from "react-icons/go";
import ServiceBg from '../../../assets/img/section_bg.jpg'
import { Button, Card } from '../../General/General';
import { Shield } from '../../icons/Icons';
import Service1 from '../../../assets/img/services/service1.svg'
import Service2 from '../../../assets/img/services/service2.svg'
import Service3 from '../../../assets/img/services/service3.svg'
import { Fade } from 'react-awesome-reveal';
const Services = () => {
  return (
    <div className='service_section position-relative' style={{height: '200vh', backgroundImage: `url(${ServiceBg})`}}>
      <div className="overlay"></div>
      <div className='d-flex flex-column align-items-center gap-3 py-5 position-relative zIndex-3'>
        <Fade>
          <Shield size="48" color="#7ee3f5"/>
        </Fade>
        <div className='w-50 d-flex flex-column gap-2 align-items-center justify-column-center '>
          <Fade cascade direction='up'>
            <h1>Step Into the Future of Innovation</h1>
            <p>Argus-X, a flagship solution by Data Prime Technologies, offers advanced location intelligence (LI) solutions to transform geospatial data into actionable insights.</p>
            <Button txt_color="#7ee3f5" border_color="#7ee3f5">Services</Button>
          </Fade>
        </div>
        <div className='px-5 mt-5 d-flex flex-row justify-content-center gap-4 serviceCardSection'>
          <Card position="0" icon={Service1} title='Geospatial Data Insights' desc='Discover actionable insights from geospatial data, enabling smarter business decisions. This service provides advanced location-based analytics tailored to retail, education, and security sectors.' c_title='Projects Analyzed' c_count='1500' endState="+" />
          <Card position="10rem" icon={Service2} title='Real-Time Insights' desc='Harness the power of real-time data processing to adapt to changing environments with precision. Empower decision-making with up-to-the-minute location intelligence.' c_title='Locations Monitored' c_count='5000' endState="+" />
          <Card position="0" icon={Service3} title='Predictive Analytics' desc='Leverage AI-driven models to forecast trends and behaviors across industries. Enhance planning and resource allocation with precise, predictive insights.' c_title='Predictive Models Deployed' c_count='300' endState="+"  />
        </div>
      </div>
    </div>
  )
}

export default Services