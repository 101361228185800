import News1 from '../../../assets/img/news/news1.jpg'
import News2 from '../../../assets/img/news/news2.jpg'
import News3 from '../../../assets/img/news/news3.jpg'
import News4 from '../../../assets/img/news/news4.jpg'

const NewsSlides = [
    { img: News1, title: 'Argus-X Unveils Real-Time Data Processing for Retail Optimization', date: 'January 2025' },
    { img: News2, title: 'Enhancing Campus Safety with Location Intelligence', date: 'December 2024' },
    { img: News3, title: 'Predictive Crime Mapping: A Revolution in Public Safety', date: 'November 2024' },
    { img: News4, title: 'AI-Driven Insights Transform Supply Chain Managements', date: 'October 2024' },
    { img: News4, title: 'Argus-X: Leading the Future of Smart Cities', date: 'September 2024' },
]

export default NewsSlides;