import Project1 from '../../../assets/img/projects/project1.jpg'
import Project2 from '../../../assets/img/projects/project2.jpg'
import Project3 from '../../../assets/img/projects/project3.jpg'
import Project4 from '../../../assets/img/projects/project4.jpg'

const projectsSlides= [
    {
        img: Project1,
        title: 'Government Infrastructure Security',
    },
    {
        img: Project2,
        title: 'Safeguarding Connected Car Data for a Leading Manufacturer',
    },
    {
        img: Project3,
        title: "Fortifying a National Government's Cybersecurity Framework",
    },
    {
        img: Project4,
        title: 'Real-Time Threat Detection for Financial Institutions',
    },
]

export default projectsSlides;