import React, { useRef } from 'react';
import './News.css';
import Slider from "react-slick"; // Import React Slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsSlides from './NewsSlides';
import { Shield } from '../../icons/Icons'
import { Button } from '../../General/General';

const News = () => {
    const sliderRef = useRef(null); // Reference to the slider

    const settings = {
        dots: false, // Enable dots navigation
        infinite: true, // Infinite loop
        speed: 500, // Slide speed in milliseconds
        slidesToShow: 1, // Number of slides to show at once
        slidesToScroll: 1, // Number of slides to scroll at once
        autoplay: false, // Enable autoplay
        autoplaySpeed: 2000, // Speed of autoplay in milliseconds
    };

    return (
        <>
            <div className="p-5 mt-5 news__section">
                <div className="row">
                    <div className="col-md-5">
                        <div className='d-flex flex-column align-items-start text-start heading__text'>
                            <Shield size={48} color="#32b1bb" />
                            <h1>Empowering Data-Driven Decisions with Argus-X</h1>
                            <p>Stay updated on the latest advancements in location intelligence and explore how Argus-X is shaping industries with cutting-edge geospatial analytics.</p>
                        </div>
                        <div className='d-flex alin-items-center justify-content-center'>
                            <Button variant="outline" txt_color="#32b1bb" border_color="#32b1bb">Latest News</Button>
                        </div>
                    </div>
                    <div className="col-md-7 px-4 rounded text-start">
                        <Slider ref={sliderRef} {...settings}>
                            {NewsSlides.map((slides, index) => (
                                <div className="news_slide" key={index}>
                                    <div
                                        className="news_slide-img"
                                        style={{ backgroundImage: `url(${slides.img})`, width: '99%' }}
                                    >
                                        <div className="news_slide-overlay"></div>
                                        <div className="text-content px-5 d-flex align-items-start justify-content-center flex-column text-white gap-2">
                                            <div className="text-content-upper">
                                                <h1>{slides.title}</h1>
                                            </div>
                                            <div className="text-content-lower">
                                                <p>{slides.date}</p>
                                            </div>
                                            <span className='btn_news text-white' style={{cursor: 'pointer'}} variant="outline">Read More</span>
                                            {/* Add Next and Prev buttons */}
                                            <div className="navigation-buttons mt-3 d-flex justify-content-start gap-3 w-100">
                                                <button
                                                    className="btn btn-outline-light prev-button"
                                                    onClick={() => sliderRef.current.slickPrev()}
                                                >
                                                    Prev
                                                </button>
                                                <button
                                                    className="btn btn-outline-light next-button"
                                                    onClick={() => sliderRef.current.slickNext()}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </>
    );
};

export default News;
