import React, { useState } from "react";
import './Innovation.css';
import { Accordion, Heading } from "../../General/General";
import Mv1 from '../../../assets/img/mv1.jpg';
import Mv2 from '../../../assets/img/mv2.jpg';
import Mv3 from '../../../assets/img/mv3.jpg';

const Innovation = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const items = [
    { title: "History", content: "Data Prime has a rich history of innovation in geospatial technology. Our journey began with the development of a proprietary geospatial processing engine. We achieved a milestone by launching Argus-X, a platform designed to provide comprehensive location intelligence. Over time, we have expanded our expertise across diverse sectors such as retail, education, and security. With a focus on scalability, security, and compliance, we have built solutions that meet the needs of organizations of all sizes. Our commitment to excellence continues to drive us forward as we set new standards in location intelligence.", img: Mv1  },
    { title: "Mission", content: "Our mission is to revolutionize the way data is utilized by transforming it into strategic assets that foster innovation and operational excellence. We aim to empower organizations with tools that make location intelligence accessible and actionable. By leveraging cutting-edge technologies, we help our clients uncover insights that drive meaningful change. Our focus is on delivering solutions that are both reliable and impactful across various industries. We prioritize creating value through data-driven decision-making processes. Ultimately, we strive to enhance the efficiency, productivity, and competitiveness of every organization we serve.", img: Mv2 },
    { title: "Vision", content: "Our vision is to establish ourselves as the leading authority in location intelligence. We aspire to be a trusted partner for organizations seeking to make confident, data-driven decisions. Our goal is to provide solutions that not only address current challenges but also anticipate future needs. By innovating continuously, we aim to set the benchmark for excellence in the industry. We envision a world where actionable location intelligence becomes a cornerstone of strategic growth. Our commitment is to empower businesses with insights that inspire progress and success.", img: Mv3 },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const selectedImg = activeIndex !== null ? items[activeIndex].img : items[0].img; // Default to the first image if no index is active.

  return (
    <div className="px-5 my-5">
      <Heading btnText="About Us" desc="Argus-X, by Data Prime Technologies, pioneers location intelligence with a mission to transform geospatial data into actionable insights. Guided by a vision to lead in location-driven solutions, it empowers industries like retail, education, and security to make informed, data-driven decisions. With a history of innovation and expertise, Argus-X delivers cutting-edge tools that drive operational excellence and foster strategic growth." title="Step Into the Future of Innovation"/>
      <div className="row">
        <div className="col-md-6">
          <Accordion
            item={items}
            toggleAccordion={toggleAccordion}
            setActiveIndex={setActiveIndex}
            activeIndex={activeIndex}
          />
        </div>
        <div className="col-md-6">
            <div style={{backgroundImage: `url(${selectedImg})`, backgroundPosition: 'center center', backgroundSize: 'cover', height: 'calc(100% + 2rem)', width: '100%'}}>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Innovation;
