import React from 'react'
import Carousel from '../Components/Home/Carousel/Carousel'
import Services from '../Components/Home/Services/Services'
import Innovation from '../Components/Home/Innovation/Innovation'
import Flow from '../Components/Home/Flow/Flow'
import News from '../Components/Home/News/News'
import Projects from '../Components/Home/Projects/Projects'
import Security from '../Components/Home/Security/Security'
import Companies from '../Components/Home/Companies/Companies'
import Offers from '../Components/Home/Offers/Offers'
import Statistics from '../Components/Home/Statistics/Statistics'
import DataVid from '../Components/Home/DataVid/DataVid'

const Home = () => {
  return (
    <>
        <Carousel />
        <Services />
        <Innovation />
        <Flow />
        <News />
        <Projects />
        <Security />
        <Companies />
        <Offers />
        <Statistics />
        <DataVid />
    </>
  )
}

export default Home