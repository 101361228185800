import './App.css';
import {Routes, Route, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import Navbar from './Components/Home/Navbar/Navbar';
import NotFound from './Components/General/NotFound';
import About from './Pages/About';
function App() {

  const location = useLocation();
console.log(location);

  const noNavbarRoutes = ['*'];

  return (
    <div className="App">
      {!noNavbarRoutes.includes(location.pathname) && <Navbar />}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/About ' element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
